import { graphql } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import Layout from "../components/layouts/layout";
import { H1, H2 } from "../components/atoms/headings";
import { P } from "../components/atoms/paragraph";
import { ExternalBlogPostLink } from "../components/atoms/links";
import SEO from "../components/seo";


const Wrapper = styled.div`
  max-width: 90ch;
  font-size: 1.05rem;
  margin: var(--top-margin) auto 0 auto;
  padding: 1rem;
  background-color: #fafafa;
  border-radius: 15px;
  box-shadow: 0 1px 3px solid grey;
  @media(min-width: 1280px) {
    padding: 5rem;
  }
`;

const RichTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

interface PrivacyPolicyProps {}

const PrivacyPolicy = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicPrivacyPolicy;

  const { lang, type, url } = doc || {};
  const alternateLanguages = doc.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };

  const seoTitle = doc.data.body.map((ele) => ele.primary.seo_title);
  const description = doc.data.body.map((ele) => ele.primary.meta_description);

  // hero
  const language = doc.lang;
  return (
    <Layout activeDocMeta={activeDoc}>
     <SEO
        title={seoTitle}
        lang={language}
        description={description}
        type="webpage"
      />

      <Wrapper style={{ marginBottom: "var(--top-margin" }}>
        <RichTextContainer>
          <PrismicRichText
            field={doc.data.body_text.richText}
            components={{
              heading1: ({ children, key}) => (
                <H1 key={key} style={{ margin: "0" }}>{children}</H1>
              ),
              heading2: ({ children, key }) => (
                <H2 key={key} style={{ margin: "0" }}>{children}</H2>
              ),
              paragraph: ({ children, key }) => (
                <P key={key} style={{ margin: "0" }}>{children}</P>
              ),
              hyperlink: ({ node, children, key }) => (
                <ExternalBlogPostLink key={key} children={children} node={node} />
              ),
            }}
          />
        </RichTextContainer>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query PPQuery($lang: String) {
    prismicPrivacyPolicy(lang: { eq: $lang }) {
      id
      lang
      url
      tags
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        body {
          ... on PrismicPrivacyPolicyDataBodyMetaTags {
            id
            primary {
              seo_title
              meta_description
            }
          }
        }
        body_text {
          richText
        }
      }
    }
  }
`;
